import React from "react";
import Console from "./common/Console";
import {
  DxcFooter,
  ThemeContext,
  DxcSpinner,
} from "@dxc-technology/halstack-react";
import linkedinLogo from "./images/linkedin.svg";
import twitterLogo from "./images/twitter.svg";
import facebookLogo from "./images/facebook.svg";
import { Header, useAssureTheme } from "@diaas/assure-platform-react-tools";
import styled from "styled-components";

function App() {
  const { isLoadingTheme, theme } = useAssureTheme();
  const year = new Date().getFullYear();
  return isLoadingTheme ? (
    <DxcSpinner label="Loading..." mode="overlay" />
  ) : (
    <ThemeContext.Provider value={theme}>
      <RootContainer>
        <Header />

        <Console />

        <DxcFooter
          copyright={`© DXC Technology ${year}. All rights reserved.`}
          bottomLinks={[
            {
              href: "https://www.linkedin.com/company/dxctechnology",
              text: "Linkedin",
            },
            {
              href: "https://twitter.com/dxctechnology",
              text: "Twitter",
            },
            {
              href: "https://www.facebook.com/DXCTechnology/",
              text: "Facebook",
            },
          ]}
          socialLinks={[
            {
              href: "https://www.linkedin.com/company/dxctechnology",
              logoSrc: linkedinLogo,
            },
            {
              href: "https://twitter.com/dxctechnology",
              logoSrc: twitterLogo,
            },
            {
              href: "https://www.facebook.com/DXCTechnology/",
              logoSrc: facebookLogo,
            },
          ]}
        ></DxcFooter>
      </RootContainer>
    </ThemeContext.Provider>
  );
}
const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default App;
