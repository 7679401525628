import React, { useState, useEffect } from "react";
import {
  DxcHeading,
  DxcBox,
  DxcInput,
  DxcLink,
  DxcSpinner,
  DxcAlert,
} from "@dxc-technology/halstack-react";
import styled from "styled-components";
import searchIcon from "../images/search.svg";
import arrow from "../images/arrowRight.svg";
import axios from "axios";
import { fakeServices } from "./fakeServices.js";
import { getSessionHeaders } from "../common/utils";

const Console = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  const groupsArray = [...new Set(services.map((item) => item.domain))];

  const closeError = () => {
    setError(null);
  };

  const realmsServiceInfoToPrint = {
    description: "User profile management for your applications",
    label: "Realms Configuration",
    path: "",
    linkText: "Start configuring users",
    domain: "Platform",
  };

  const uxServiceInfoToPrint = {
    description: "Custom branding for your applications",
    label: "UX Configuration",
    path: "",
    linkText: "Start branding applications",
    domain: "Platform",
  };

  const getInfoToBePrinted = (service) => {
    if (service.description.indexOf("Realms") > -1) {
      return { ...realmsServiceInfoToPrint, path: service.path };
    }
    if (service.description.indexOf("UX") > -1) {
      return { ...uxServiceInfoToPrint, path: service.path };
    }
    return service;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!window?.assureConfig?.dev) {
        const applicationName = window?.assureConfig?.applicationName;
        const sessionHeaders = await getSessionHeaders();
        axios({
          method: "get",
          url: `${window.assureConfig.platformconsoleApiBaseUrl}?applicationName=${applicationName}`,
          headers: {
            ...window.assureConfig.apiHeaders,
            ...sessionHeaders,
          },
        })
          .then((response) => {
            setServices(response?.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
            setIsLoading(false);
          });
      } else {
        setServices(fakeServices);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {isLoading && (
        <SpinnerContainer>
          <DxcSpinner label="Loading" />
        </SpinnerContainer>
      )}
      {!isLoading && (
        <ConsoleContainer>
          <ErrorAlert>
            {error && (
              <DxcAlert
                type="error"
                mode="inline"
                onClose={closeError}
                inlineText={error.toJSON().message}
              />
            )}
          </ErrorAlert>
          <GreyDiv />
          <Container>
            <TitleContainer>
              <DxcHeading
                level={2}
                margin={{ bottom: "xlarge" }}
                text="DXC Assure Platform Console"
              />
            </TitleContainer>
            <BodyContainer>
              {services.map((service) => {
                let serviceToPrint = {};
                serviceToPrint = getInfoToBePrinted(service);
                return (
                  serviceToPrint.domain === "Platform" && (
                    <ConfigContainer>
                      <ConfigTextContainer>
                        <DescriptionContainer>
                          {serviceToPrint.description}
                        </DescriptionContainer>
                        <DxcHeading level={3} text={serviceToPrint.label} />
                        <DxcLink
                          href={serviceToPrint.path}
                          text={serviceToPrint.linkText || serviceToPrint.label}
                          iconSrc={arrow}
                          newWindow={true}
                          iconPosition="after"
                          underlined={false}
                        />
                      </ConfigTextContainer>
                    </ConfigContainer>
                  )
                );
              })}
            </BodyContainer>
            <ServicesContainer>
              <DxcBox
                size="fillParent"
                padding={{
                  top: "medium",
                  right: "medium",
                  left: "medium",
                  bottom: "large",
                }}
              >
                <ServicesListContainer>
                  <SearchContainer>
                    <DxcHeading level={5} text="All services" />
                    <DxcInput
                      label="Search a product from your solution..."
                      prefixIconSrc={searchIcon}
                      size="fillParent"
                      margin={{
                        top: "large",
                        bottom: "xlarge",
                      }}
                    />
                  </SearchContainer>
                  {(groupsArray.length === 0 && (
                    <NoItemsContainer>There are no services.</NoItemsContainer>
                  )) || (
                    <ServicesResultListContainer>
                      {groupsArray.map((group) => {
                        return (
                          group !== "Platform" && (
                            <ServicesList>
                              <ServiceIconContainer>
                                <DxcHeading level={5} text={group} />
                              </ServiceIconContainer>
                              <div>
                                {services.map((service) => {
                                  return (
                                    service.domain !== "Platform" && (
                                      <div>
                                        <DxcLink
                                          href={service.path}
                                          text={service.label}
                                          underlined={false}
                                          margin={{ bottom: "xxsmall" }}
                                        />
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                            </ServicesList>
                          )
                        );
                      })}
                    </ServicesResultListContainer>
                  )}
                </ServicesListContainer>
              </DxcBox>
            </ServicesContainer>
          </Container>
        </ConsoleContainer>
      )}
    </React.Fragment>
  );
};
const NoItemsContainer = styled.div`
  width: 100%;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
`;
const ErrorAlert = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 100px;
  right: 48px;
`;
const SpinnerContainer = styled.div`
  margin: 0 auto;
  min-height: calc(100vh - 64px - 119px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ConsoleContainer = styled.div`
  margin-bottom: 100px;
  margin-top: 80px;
  flex-grow: 1;
`;

const DescriptionContainer = styled.p`
  margin: 0;
`;
const ConfigContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 120px;
`;
const ConfigTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Container = styled.div`
  width: calc(100% - 15% - 15%);
  margin-left: 15%;
  margin-right: 15%;
`;
const GreyDiv = styled.div`
  background-color: #f2f2f2;
  top: 64px;
  position: absolute;
  width: 100%;
  height: 480px;
  z-index: -1;
`;
const ServiceIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 27px;
  margin-bottom: 19px;
`;
const ServicesList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const ServicesListContainer = styled.div`
  width: 100%;
`;
const ServicesResultListContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    margin-right: 100px;
    margin-bottom: 36px;
    width: calc(100% / 4 - 100px);
  }
`;
const SearchContainer = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div`
  display: flex;
  margin-top: 64px;
  margin-bottom: 96px;
`;
const ServicesContainer = styled.div`
  width: 100%;
  background-color: white;
`;
const TitleContainer = styled.div`
  height: 65px;
`;

export default Console;
